// i18next-extract-mark-ns-start article

import React from 'react'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import { DynamicallyStyledPageTitle } from 'src/components/typography/PageTitle'
import Article from 'src/components/typography/Article'
import Section from 'src/components/typography/Section'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import { Link } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { PlainSubtitle } from 'src/components/typography/HighlightedSubtitle'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import { StyledListWrapper } from 'src/components/typography/List'
import tw from 'twin.macro'
import styled from 'styled-components'
import ParallaxImage from 'src/components/parallax-image'
import SemiRoundedButton, {
  ButtonSizes,
} from 'src/components/ui/SemiRoundedButton'
import StyledHtmlColorOverwrite from 'src/components/typography/StyledHtmlColorOverwrite'
import StyledSubtitleColorOverwrite from 'src/components/typography/StyledSubtitleColorOverwrite'
import themeColors from 'src/helpers/theme-colors'
import WhatFootballWantsSvg from 'src/images/SVG/work-process/what-football-wants.inline.svg'
import WhatFootballWantsSvgDe from 'src/images/SVG/work-process/what-football-wants-de.inline.svg'
import WhatFootballWantsSvgEs from 'src/images/SVG/work-process/what-football-wants-es.inline.svg'
import WhatFootballWantsSvgFr from 'src/images/SVG/work-process/what-football-wants-fr.inline.svg'
import CircleDiagram from 'src/images/SVG/work-process/circle-diagram.inline.svg'
import CircleDiagramDe from 'src/images/SVG/work-process/circle-diagram-de.inline.svg'
import CircleDiagramEs from 'src/images/SVG/work-process/circle-diagram-es.inline.svg'
import CircleDiagramFr from 'src/images/SVG/work-process/circle-diagram-fr.inline.svg'
import CircleDiagramMobile from 'src/images/SVG/work-process/circle-diagram-mobile.inline.svg'
import CircleDiagramMobileDe from 'src/images/SVG/work-process/circle-diagram-mobile-de.inline.svg'
import CircleDiagramMobileEs from 'src/images/SVG/work-process/circle-diagram-mobile-es.inline.svg'
import CircleDiagramMobileFr from 'src/images/SVG/work-process/circle-diagram-mobile-fr.inline.svg'
import { useAnimateCircleDiagrams } from 'src/components/layout/animations/work-process-animations'
import { useAnimateCircleDiagramsMobile } from 'src/components/layout/animations/work-process-animations-mobile'
import { useShowSectionsOneAfterAnother } from 'src/components/layout/animations/one-after-another'

const StyledPlainSubtitle = styled(PlainSubtitle)`
  ${tw`mb-0`}
`

const IndexPage = ({ data: { model }, pageContext: { language } }) => {
  useAnimateCircleDiagrams()
  useAnimateCircleDiagramsMobile()

  useShowSectionsOneAfterAnother(
    '#more, #reduced, #increase, #improved, #logo_full, #yellow-highlight'
  )

  const getCircleDiagramSvg = () => {
    switch (language) {
      case 'en':
        return <CircleDiagram />
      case 'de':
        return <CircleDiagramDe />
      case 'es':
        return <CircleDiagramEs />
      case 'fr':
        return <CircleDiagramFr />

      default:
        return <></>
    }
  }

  const getCircleDiagramMobileSvg = () => {
    switch (language) {
      case 'en':
        return <CircleDiagramMobile />
      case 'de':
        return <CircleDiagramMobileDe />
      case 'es':
        return <CircleDiagramMobileEs />
      case 'fr':
        return <CircleDiagramMobileFr />

      default:
        return <></>
    }
  }

  const whatFootballWantsClasses = 'w-full block max-w-xl mx-auto'
  const whatFootballWantsAlt = 'What football wants'

  const getWhatFootballWantsSvg = () => {
    switch (language) {
      case 'en':
        return (
          <WhatFootballWantsSvg
            className={whatFootballWantsClasses}
            alt={whatFootballWantsAlt}
          />
        )
      case 'de':
        return (
          <WhatFootballWantsSvgDe
            className={whatFootballWantsClasses}
            alt={whatFootballWantsAlt}
          />
        )
      case 'es':
        return (
          <WhatFootballWantsSvgEs
            className={whatFootballWantsClasses}
            alt={whatFootballWantsAlt}
          />
        )
      case 'fr':
        return (
          <WhatFootballWantsSvgFr
            className={whatFootballWantsClasses}
            alt={whatFootballWantsAlt}
          />
        )

      default:
        return <></>
    }
  }

  return (
    <>
      <Helmet>
        <title>The IFAB Work Process</title>
      </Helmet>
      <Layout>
        <div className="bg-navyBlueGray overflow-hidden">
          <ContentPageContainer>
            <Section isNoMargin>
              <DynamicallyStyledPageTitle
                isSticked={false}
                className="whitespace-pre"
                style={{ minHeight: '100px' }}
              >
                <span className="text-almostBlackBlue whitespace-pre hidden md:inline">
                  {model.title}
                </span>
                <span className="text-almostBlackBlue whitespace-pre inline md:hidden">
                  {model.mobileTitle}
                </span>
              </DynamicallyStyledPageTitle>
              <StyledSubtitleColorOverwrite>
                {model.subtitle}
              </StyledSubtitleColorOverwrite>
              <Article className="mb-0" noMarginDesktop noMarginMobile>
                <div className="block mt-14 overflow-auto">
                  <div className="desktop w-full hidden md:block m-auto">
                    {getCircleDiagramSvg()}
                  </div>
                  <div className="mobile w-full block md:hidden m-auto">
                    {getCircleDiagramMobileSvg()}
                  </div>
                </div>
                <StyledHtmlColorOverwrite
                  color={themeColors.almostBlackBlue}
                  className="mt-14"
                >
                  <StyledListWrapper>
                    <CmsHtmlContent
                      className="text-white"
                      dangerouslySetInnerHTML={{ __html: model.section }}
                    />
                  </StyledListWrapper>
                </StyledHtmlColorOverwrite>
                <div className="mt-12">
                  <StyledPlainSubtitle className="text-brightYellow">
                    <span className="text-brightYellow whitespace-pre-line hidden md:inline">
                      {model.highlightedText}
                    </span>
                    <span className="text-brightYellow whitespace-pre-line inline md:hidden">
                      {model.highlightedTextMobile}
                    </span>
                  </StyledPlainSubtitle>
                </div>
              </Article>
            </Section>
          </ContentPageContainer>
        </div>
        <ParallaxImage
          isNavyBlueGrayBackground
          desktopImage={model.transitionPicture[0].desktopImage}
          mobileImage={model.transitionPicture[0].mobileImage}
          id={model.transitionPicture[0].id}
        />
        <div className="bg-navyBlueGray overflow-hidden">
          <ContentPageContainer noPadding className="mt-8">
            <Section isNoMargin>
              <StyledSubtitleColorOverwrite>
                {model.subtitle2}
              </StyledSubtitleColorOverwrite>
              <StyledHtmlColorOverwrite
                color={themeColors.almostBlackBlue}
                className=""
              >
                <StyledListWrapper>
                  <CmsHtmlContent
                    className="text-white"
                    dangerouslySetInnerHTML={{ __html: model.section2 }}
                  />
                </StyledListWrapper>
              </StyledHtmlColorOverwrite>
              <div className="mt-16 overflow-auto">
                {getWhatFootballWantsSvg()}
              </div>
              <StyledHtmlColorOverwrite
                color={themeColors.almostBlackBlue}
                className="mt-12 mb-16"
              >
                <StyledListWrapper>
                  <CmsHtmlContent
                    className="text-white"
                    dangerouslySetInnerHTML={{ __html: model.section3 }}
                  />
                </StyledListWrapper>
              </StyledHtmlColorOverwrite>
              <StyledSubtitleColorOverwrite>
                {model.subtitle3}
              </StyledSubtitleColorOverwrite>
              <StyledHtmlColorOverwrite
                color={themeColors.almostBlackBlue}
                className="mb-12"
              >
                <StyledListWrapper>
                  <CmsHtmlContent
                    className="text-white"
                    dangerouslySetInnerHTML={{ __html: model.section4 }}
                  />
                </StyledListWrapper>
              </StyledHtmlColorOverwrite>
              <div className="flex justify-center mt-12 mb-24">
                <Link to={model.buttonLink}>
                  <SemiRoundedButton className="" size={ButtonSizes.medium}>
                    {model.buttonText}
                  </SemiRoundedButton>
                </Link>
              </div>
            </Section>
          </ContentPageContainer>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    model: datoCmsWorkProcessPage(locale: { eq: $language }) {
      title
      mobileTitle
      subtitle
      processImage {
        url
        alt
      }
      processImageMobile {
        url
        alt
      }
      highlightedText
      highlightedTextMobile
      section
      transitionPicture {
        ... on DatoCmsTransitionImage {
          id
          model {
            apiKey
          }
          mobileImage {
            url
            alt
          }
          desktopImage {
            url
            alt
          }
        }
      }
      goalsImage {
        url
        alt
      }
      subtitle2
      section2
      section3
      subtitle3
      section4
      buttonLink
      buttonText
    }
  }
`

export default IndexPage
