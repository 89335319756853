import styled from 'styled-components'

interface IStyledHtmlColorOverwriteProps {
  color: string
}

const StyledHtmlColorOverwrite = styled.div<IStyledHtmlColorOverwriteProps>`
  body & * {
    color: ${({ color }) => color};
    border-color: ${({ color }) => color};
  }
`

export default StyledHtmlColorOverwrite
