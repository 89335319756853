import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'

gsap.registerPlugin(ScrollTrigger)

const sortNodesByTopOffset = (items) =>
  items.sort((a, b) => {
    if (a.getBoundingClientRect().top - b.getBoundingClientRect().top) {
      return -1
    }

    if (b.getBoundingClientRect().top - a.getBoundingClientRect().top) {
      return 1
    }

    return 0
  })

export const useShowSectionsOneAfterAnother = (selector: string) => {
  const timeline = useRef(gsap.timeline())
  const isLarge = useIsLargeScreen()

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    const elements = sortNodesByTopOffset([
      ...document.querySelectorAll(selector),
    ])

    gsap.set(elements, {
      opacity: 0,
    })

    ScrollTrigger.batch(elements, {
      batchMax: 1,
      start: '150px bottom',
      onEnter: (el) => {
        timeline.current.to(el, {
          opacity: 1,
          duration: 0.75,
        })
      },
    })
  }, [isLarge])
}

export default {}
