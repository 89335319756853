import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef } from 'react'
import { useIsMdScreen } from 'src/helpers/layout'

gsap.registerPlugin(ScrollTrigger)

export const useAnimateCircleDiagramsMobile = () => {
  const timeline = useRef(gsap.timeline())
  const isMdScreen = useIsMdScreen()

  useEffect(() => {
    if (isMdScreen === null || isMdScreen === true) {
      return () => {}
    }

    gsap.set('.mobile #_1, .mobile #_1b', {
      y: -20,
    })
    gsap.set('.mobile #_2, .mobile #_2b', {
      y: -20,
    })
    gsap.set('.mobile #_3, .mobile #_3b', {
      y: -20,
    })
    gsap.set('.mobile #_4, .mobile #_4b', {
      y: -20,
    })

    ScrollTrigger.create({
      trigger: '.mobile #_1',
      start: '140px bottom',
      onEnter: () => {
        timeline.current.to('.mobile #logo_full', {
          opacity: 1,
          duration: 0.75,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.mobile #_1',
      start: '140px bottom',
      onEnter: () => {
        timeline.current.to('.mobile #annual_cycle, .mobile #line', {
          opacity: 1,
          duration: 0.75,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.mobile #logo_full',
      start: '140px bottom',
      scrub: true,
      onEnter: () => {
        timeline.current.to('.mobile #arrow_line', {
          opacity: 1,
          duration: 0.75,
          y: 0,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.mobile #logo_full',
      start: '140px bottom',
      scrub: true,
      onEnter: () => {
        timeline.current.to('.mobile #_1, .mobile #_1b', {
          opacity: 1,
          duration: 0.75,
          y: 0,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.mobile #_2b',
      start: '140px bottom',
      onEnter: () => {
        timeline.current.to('.mobile #_2, .mobile #_2b', {
          opacity: 1,
          duration: 0.75,
          y: 0,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.mobile #_3b',
      start: '140px bottom',
      onEnter: () => {
        timeline.current.to('.mobile #_3, .mobile #_3b', {
          opacity: 1,
          duration: 0.75,
          y: 0,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.mobile #_4b',
      start: '140px bottom',
      onEnter: () => {
        timeline.current.to('.mobile #_4, .mobile #_4b', {
          opacity: 1,
          duration: 0.75,
          y: 0,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.mobile #revisions',
      start: '140px bottom',
      onEnter: () => {
        timeline.current.to('.mobile #revisions, .mobile #revisions', {
          opacity: 1,
          duration: 0.75,
          y: 0,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '.mobile #ongoing',
      start: '140px bottom',
      onEnter: () => {
        timeline.current.to('.mobile #ongoing, .mobile #ongoing', {
          opacity: 1,
          duration: 0.75,
          y: 0,
        })
      },
    })

    ScrollTrigger.sort()

    const speedUpAnimation = () => {
      const SLOW_DOWN_RATIO = 0.8
      const timeNeededToAnimatePlannedAnimations =
        timeline.current.totalDuration() - timeline.current.time()

      const scaledTime = timeNeededToAnimatePlannedAnimations * SLOW_DOWN_RATIO

      if (scaledTime > 1) {
        timeline.current.timeScale(scaledTime)
        return
      }

      timeline.current.timeScale(1)
    }

    gsap.ticker.add(speedUpAnimation)

    return () => {
      gsap.ticker.remove(speedUpAnimation)
    }
  })
}

export default {}
