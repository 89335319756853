import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef } from 'react'
import { useIsMdScreen } from 'src/helpers/layout'

gsap.registerPlugin(ScrollTrigger)

export const useAnimateCircleDiagrams = () => {
  const timeline = useRef(gsap.timeline())
  const isMdScreen = useIsMdScreen()

  useEffect(() => {
    if (!isMdScreen) {
      return
    }

    gsap.set('.desktop #_01-2', {
      x: -20,
    })
    gsap.set('.desktop #_02-2', {
      x: 20,
    })
    gsap.set('.desktop #_03-2', {
      x: -20,
    })
    gsap.set('.desktop #_04-2', {
      x: 20,
    })

    gsap.set('.desktop #lines', {
      scaleY: 0,
      opacity: 1,
    })

    ScrollTrigger.create({
      trigger: '.desktop #_01-2',
      start: '150px bottom',
      refreshPriority: 130,
      onEnter: () => {
        timeline.current.to(
          '.desktop #Logo, .desktop #line',
          {
            opacity: 1,
            duration: 0.75,
          },
          0
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_01-2',
      start: '150px bottom',
      refreshPriority: 120,
      onEnter: () => {
        timeline.current.to(
          '.desktop #annual_cycle',
          {
            opacity: 1,
            duration: 0.75,
          },
          0.5
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_01-2',
      start: '150px bottom',
      refreshPriority: 100,
      onEnter: () => {
        if (timeline.current) {
          timeline.current.to(
            '.desktop #_01-2',
            {
              opacity: 1,
              x: 0,
              duration: 0.75,
            },
            1
          )
        }
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_01-2',
      start: '150px bottom',
      refreshPriority: 90,
      onEnter: () => {
        timeline.current.to(
          '.desktop #arrow_1',
          {
            opacity: 1,
            x: 0,
            duration: 0.75,
          },
          1.5
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_01-2',
      start: '150px bottom',
      refreshPriority: 80,
      onEnter: () => {
        timeline.current.to(
          '.desktop #_02-2',
          {
            opacity: 1,
            x: 0,
            duration: 0.75,
          },
          2
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_03-2',
      start: '150px bottom',
      refreshPriority: 70,
      onEnter: () => {
        if (timeline.current) {
          timeline.current.to(
            '.desktop #arrow_2',
            {
              opacity: 1,
              duration: 0.75,
            },
            2.25
          )
        }
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_03-2',
      start: '150px bottom',
      refreshPriority: 60,
      onEnter: () => {
        timeline.current.to(
          '.desktop #_03-2',
          {
            opacity: 1,
            x: 0,
            duration: 0.75,
          },
          2.75
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_04-2',
      start: '150px bottom',
      refreshPriority: 50,
      onEnter: () => {
        timeline.current.to(
          '.desktop #arrow_3',
          {
            opacity: 1,
            duration: 0.75,
          },
          3
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_04-2',
      start: '150px bottom',
      refreshPriority: 40,
      onEnter: () => {
        timeline.current.to(
          '.desktop #_04-2',
          {
            opacity: 1,
            x: 0,
            duration: 0.75,
          },
          3.5
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_04-2',
      start: '150px bottom',
      refreshPriority: 30,
      onEnter: () => {
        timeline.current.to(
          '.desktop #arrow_4',
          {
            opacity: 1,
            duration: 0.75,
          },
          3.75
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_04-2',
      start: '150px bottom',
      refreshPriority: 20,
      onEnter: () => {
        timeline.current.to(
          '.desktop #lines',
          {
            duration: 2,
            scaleY: 1,
          },
          3.5
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_04-2',
      start: '150px bottom',
      refreshPriority: 10,
      onEnter: () => {
        timeline.current.to(
          '.desktop #ongoing_rev',
          {
            opacity: 1,
            duration: 0.75,
          },
          4.25
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_04-2',
      start: '150px bottom',
      refreshPriority: 10,
      onEnter: () => {
        timeline.current.to(
          '.desktop #tech_sub',
          {
            opacity: 1,
            duration: 0.75,
          },
          4.5
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_04-2',
      start: '150px bottom',
      refreshPriority: 10,
      onEnter: () => {
        timeline.current.to(
          '.desktop #board_direc',
          {
            opacity: 1,
            duration: 0.75,
          },
          4.75
        )
      },
    })

    ScrollTrigger.create({
      trigger: '.desktop #_04-2',
      start: '150px bottom',
      refreshPriority: 10,
      onEnter: () => {
        timeline.current.to(
          '.desktop #fifa_qual',
          {
            opacity: 1,
            duration: 0.75,
          },
          5
        )
      },
    })
  })
}

export default {}
